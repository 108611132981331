import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MyAccountSignInContainer as SourceMyAccountSignInContainer
} from 'SourceComponent/MyAccountSignIn/MyAccountSignIn.container';
import { getErrorMessage } from 'Util/Request';

/** @namespace ZnetPwa/Component/MyAccountSignIn/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    isCaptchaEnabled: state.ConfigReducer.captcha_is_enabled
});

/** @namespace ZnetPwa/Component/MyAccountSignIn/Container/MyAccountSignInContainer */
export class MyAccountSignInContainer extends SourceMyAccountSignInContainer {
    static propTypes = {
        ...super.propTypes,
        isCaptchaEnabled: PropTypes.bool
    };

    static defaultProps = {
        ...this.defaultProps,
        isCaptchaEnabled: false
    };

    state = {
        ...this.state,
        isSetToken: false,
        isFormSubmittedUnsuccessfully: false,
        token: false,
        showError: false
    };

    containerFunctions = {
        ...this.containerFunctions,
        setToken: this.setToken.bind(this),
        handleSignIn: this.handleSignIn.bind(this)
    };

    containerProps = () => {
        const {
            isFormSubmittedUnsuccessfully,
            showError
        } = this.state;

        const {
            state,
            onFormError,
            handleForgotPassword,
            handleCreateAccount,
            isCheckout,
            setLoadingState,
            emailValue,
            handleEmailInput
        } = this.props;

        return {
            state,
            showError,
            hideError: window.location.pathname === '/checkout/shipping',
            onFormError,
            handleForgotPassword,
            handleCreateAccount,
            isCheckout,
            setLoadingState,
            emailValue,
            handleEmailInput,
            isFormSubmittedUnsuccessfully
        };
    };

    setToken(token) {
        this.setState({ token, isSetToken: true });
    }

    async onSignInSuccess(fields) {
        const {
            signIn,
            showNotification,
            onSignIn,
            setLoadingState,
            isCaptchaEnabled
        } = this.props;

        const {
            token
        } = this.state;

        if (!token && isCaptchaEnabled) {
            return;
        }

        const fieldsWithCaptcha = {
            ...fields,
            captcha_token: token
        };

        try {
            await signIn(fieldsWithCaptcha);
            onSignIn();
        } catch (error) {
            showNotification('error', getErrorMessage(error));

            this.setState({ isFormSubmittedUnsuccessfully: true });
        }

        setLoadingState(false);
    }

    handleSignIn() {
        if (window.location.pathname === '/checkout/shipping') {
            this.setState({ showError: true });
        }
    }

    onSignInAttempt() {
        const { setLoadingState, isCaptchaEnabled, showNotification } = this.props;

        if (isCaptchaEnabled) {
            const {
                isSetToken,
                token
            } = this.state;

            this.setState({ isFormSubmittedUnsuccessfully: false });

            if (!token) {
                showNotification('info', __('Missing captcha'));
                setLoadingState(false);

                if (isSetToken) {
                    this.setState({ isFormSubmittedUnsuccessfully: true, isSetToken: false });
                }

                return;
            }
        }

        setLoadingState(true);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountSignInContainer);
