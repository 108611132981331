/* eslint-disable max-lines */
import PropTypes from 'prop-types';
import { createRef } from 'react';
import { connect } from 'react-redux';
import { Subscribe } from 'unstated';

import { DISCONTINUED_PRODUCT_LABELS } from 'Component/RequestQuoteButtons/RequestQuoteButtons.config';
import SharedTransitionContainer from 'Component/SharedTransition/SharedTransition.unstated';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourcemapStateToProps,
    ProductCardContainer as SourceProductCardContainer
} from 'SourceComponent/ProductCard/ProductCard.container';
import { showNotification } from 'SourceStore/Notification/Notification.action';
import { toggleOverlayByKey } from 'Store/Overlay/Overlay.action';
import { customerType } from 'Type/Account';
import { TotalsType } from 'Type/MiniCart';
import { ProductType } from 'Type/ProductList';
import { ConfigurableProductSwatchType } from 'Type/SearchSpringSearch';
import Event, { EVENT_GTM_PRODUCT_ADD_TO_CART } from 'Util/Event';

import ProductCard from './ProductCard.component';
import {
    DEFAULT_NUM_SWATCHES_DESKTOP,
    DEFAULT_NUM_SWATCHES_MOBILE,
    DISCONTINUED,
    IN_STOCK
} from './ProductCard.config';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

/** @namespace ZnetPwa/Component/ProductCard/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourcemapStateToProps(state),
    placeholderImageUrl: state.ConfigReducer.placeholder_image,
    cartTotals: state.CartReducer.cartTotals,
    device: state.ConfigReducer.device,
    customer: state.MyAccountReducer
});

/** @namespace ZnetPwa/Component/ProductCard/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    changeItemQty: (options) => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.changeItemQty(dispatch, options)
    ),
    removeProduct: (options) => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.removeProductFromCart(dispatch, options)
    ),
    updateCrossSellProducts: (items) => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateCrossSellProducts(items, dispatch)
    ),
    showOverlay: (overlayKey) => dispatch(toggleOverlayByKey(overlayKey)),
    showNotification: (type, message) => dispatch(showNotification(type, message))
});

/** @namespace ZnetPwa/Component/ProductCard/Container/ProductCardContainer */
export class ProductCardContainer extends SourceProductCardContainer {
    static propTypes = {
        ...this.propTypes,
        customer: customerType.isRequired,
        cartTotals: TotalsType.isRequired,
        isRequiredAccessory: PropTypes.bool,
        isEqualHeight: PropTypes.bool,
        isSearchSpringSearch: PropTypes.bool,
        placeholderImageUrl: PropTypes.string.isRequired,
        // eslint-disable-next-line react/forbid-prop-types
        linkedProducts: PropTypes.object,
        // eslint-disable-next-line react/forbid-prop-types
        simpleProductSwatches: PropTypes.array,
        configurableProductsSwatches: ConfigurableProductSwatchType,
        onQuickViewClick: PropTypes.func,
        isMolding: PropTypes.bool,
        linkType: PropTypes.string,
        sample: ProductType.isRequired,
        toggleDisableSampleButton: PropTypes.func,
        isSamplesDisabled: PropTypes.bool,
        isPreview: PropTypes.bool,
        recordSearchSpringProductClick: PropTypes.func,
        isScroll: PropTypes.bool.func
    };

    static defaultProps = {
        ...this.defaultProps,
        onQuickViewClick: () => {},
        isRequiredAccessory: false,
        isSearchSpringSearch: false,
        isEqualHeight: false,
        isMolding: false,
        simpleProductSwatches: [],
        configurableProductsSwatches: {},
        linkedProducts: {},
        linkType: '',
        numberOfSwatchesToDisplayDesktop: DEFAULT_NUM_SWATCHES_DESKTOP,
        numberOfSwatchesToDisplayMobile: DEFAULT_NUM_SWATCHES_MOBILE,
        toggleDisableSampleButton: () => {},
        isSamplesDisabled: false,
        isPreview: false,
        recordSearchSpringProductClick: null
    };

    state = {
        quantity: 1,
        accessoryQuantity: 1,
        isConfirmQtyVisible: false,
        upSellDescription: {},
        isSwatchPopupOpen: false,
        isRemovingProducts: false,
        isAddingAccessory: false,
        isCustomQtyInputVisible: false,
        activeQtyInput: -1
    };

    qtyInputRef = createRef();

    containerFunctions = {
        ...this.containerFunctions,
        onError: this.onError.bind(this),
        changeToSwatchImage: this.changeToSwatchImage.bind(this),
        changeToProductImage: this.changeToProductImage.bind(this),
        setQuantity: this.setQuantity.bind(this),
        showMore: this.showMore.bind(this),
        removeSample: this.removeSample.bind(this),
        showOnlyIfLoaded: this.showOnlyIfLoaded.bind(this),
        isSampleInCart: this.isSampleInCart.bind(this),
        toggleProductPopUp: this.toggleProductPopUp.bind(this),
        onPopUpOutsideClick: this.onPopUpOutsideClick.bind(this),
        onAccessoryAddToCartClick: this.onAccessoryAddToCartClick.bind(this),
        onAccessoryAddToCart: this.onAccessoryAddToCart.bind(this),
        onConfirmQtyChange: this.onConfirmQtyChange.bind(this),
        toggleCustomQtyInput: this.toggleCustomQtyInput.bind(this),
        hideConfirmQty: this.hideConfirmQty.bind(this),
        onConfirmClick: this.onConfirmClick.bind(this),
        handleRemoveAccessory: this.handleRemoveAccessory.bind(this)
    };

    containerProps = () => {
        const {
            isAddingAccessory,
            isConfirmQtyVisible,
            isCustomQtyInputVisible
        } = this.state;
        const {
            isScroll,
            customer: {
                customer: {
                    customerGroupName = ''
                } = {}
            } = {},
            simpleProductSwatches,
            configurableProductsSwatches: {
                products: configurableProducts,
                minPrice: configurableMinPrice,
                maxPrice: configurableMaxPrice
            } = {},
            product: {
                dynamicAttributes: {
                    discontinued_product = ''
                } = {}
            },
            toggleDisableSampleButton,
            isSamplesDisabled,
            recordSearchSpringProductClick
        } = this.props;
        const isConfigurableSwatchPopUp = !simpleProductSwatches?.length && configurableProducts?.length;

        return {
            isScroll,
            customerGroupName,
            configurableMinPrice,
            configurableMaxPrice,
            isConfigurableSwatchPopUp,
            popUpProducts: isConfigurableSwatchPopUp ? configurableProducts
                : this._processProducts(simpleProductSwatches),
            availableVisualOptions: this._getAvailableVisualOptions(),
            currentVariantIndex: this._getCurrentVariantIndex(),
            productOrVariant: this._getProductOrVariant(),
            thumbnail: this._getThumbnail(),
            linkTo: this._getLinkTo(),
            isAddingAccessory,
            isConfirmQtyVisible,
            toggleDisableSampleButton,
            isSamplesDisabled,
            isCustomQtyInputVisible,
            qtyInputRef: this.qtyInputRef,
            recordSearchSpringProductClick,
            isDiscontinued: DISCONTINUED_PRODUCT_LABELS.includes(discontinued_product)
        };
    };

    /** Filter out discontinued products for swatch popup on PLP */
    _processProducts(products) {
        const filteredDiscountinued = products.filter(
            (product) => !product?.discontinuedLabel || !product?.discontinuedLabel.includes(DISCONTINUED)
        );

        return filteredDiscountinued;
    }

    toggleProductPopUp() {
        const { isSwatchPopupOpen } = this.state;

        this.setState({ isSwatchPopupOpen: !isSwatchPopupOpen });
    }

    onPopUpOutsideClick() {
        this.setState({ isSwatchPopupOpen: false });
    }

    toggleCustomQtyInput() {
        const {
            product: {
                id
            } = {}
        } = this.props;
        const { isCustomQtyInputVisible } = this.state;

        this.setState({
            isCustomQtyInputVisible: !isCustomQtyInputVisible
        }, () => {
            // Fix for: QTY input with confirm options required 2 clicks to get focused
            if (!isCustomQtyInputVisible) {
                document.getElementById(`AddToCartQty-custom-${ id }`).focus();
            }
        });
    }

    showConfirmQty() {
        this.setState({
            isConfirmQtyVisible: true
        });
    }

    hideConfirmQty() {
        this.toggleCustomQtyInput();
        this.setState({
            isConfirmQtyVisible: false
        });
    }

    onConfirmQtyChange(value) {
        const newQty = value === '' ? value : +value;

        this.showConfirmQty();
        this.setState({ accessoryQuantity: newQty });
    }

    onConfirmClick() {
        const { accessoryQuantity } = this.state;

        this.onAccessoryAddToCart(accessoryQuantity);
        this.hideConfirmQty();
        this.toggleCustomQtyInput();
    }

    onAccessoryAddToCartClick(id, value) {
        this.setState({
            activeQtyInput: id
        }, () => {
            this.onAccessoryAddToCart(value);
            this.setState({ accessoryQuantity: 1 });
        });
    }

    onAccessoryAddToCart(value) {
        this.setState({ accessoryQuantity: +value, isAddingAccessory: true },
            () => this.handleAccessoryAddToCart());
    }

    /**
     * Used to trigger 'add_to_cart_ga4' GA4 event
     * Ref: https://sepoy.atlassian.net/browse/ZFR-1356
     */
    dispatchGA4Event(isChangeQty, variants, product, configurableVariantIndex, accessoryQuantity, item = {}) {
        if (isChangeQty) {
            const {
                qty
            } = item;
            const qtyToAdd = qty >= accessoryQuantity ? accessoryQuantity : accessoryQuantity - qty;
            const productToAdd = variants
                ? { ...product, configurableVariantIndex }
                : product;

            Event.dispatch(EVENT_GTM_PRODUCT_ADD_TO_CART, {
                product: productToAdd,
                quantity: qtyToAdd,
                configurableVariantIndex
            });
        } else {
            const productToAdd = variants
                ? { ...product, configurableVariantIndex }
                : product;

            Event.dispatch(EVENT_GTM_PRODUCT_ADD_TO_CART, {
                product: productToAdd,
                quantity: accessoryQuantity,
                configurableVariantIndex
            });
        }
    }

    async handleAccessoryAddToCart() {
        const {
            addProduct,
            product,
            product: {
                variants
            },
            productOptionsData = {},
            cartTotals: {
                items
            } = {},
            showNotification,
            configurableVariantIndex
        } = this.props;

        const { accessoryQuantity } = this.state;
        const filteredItems = items?.filter((item) => item.sku === product.sku);

        if (filteredItems?.length) {
            const item = filteredItems[0];

            if (accessoryQuantity === 0) {
                this.handleRemoveAccessory(item);
                return;
            }

            this.handleAccessoryChangeQuantity(accessoryQuantity, item);
        } else {
            addProduct({
                product,
                quantity: accessoryQuantity,
                productOptionsData
            }).then(
                /** @namespace ZnetPwa/Component/ProductCard/Container/addProduct/then */
                () => {
                    this.dispatchGA4Event(false, variants, product, configurableVariantIndex, accessoryQuantity);
                    this.setState({ isAddingAccessory: false });
                    showNotification('success', __('Product added to cart!'));
                },
                // Setting qty to 0 to show the add to cart button because accessory is out of stock
                /** @namespace ZnetPwa/Component/ProductCard/Container/addProduct/then */
                () => this.setState({ isAddingAccessory: false, accessoryQuantity: 0 })
            );
        }
    }

    async handleAccessoryChangeQuantity(quantity, item) {
        const { changeItemQty, showNotification } = this.props;
        const { item_id, sku } = item;

        await changeItemQty({ item_id, quantity, sku });
        this.setState({ isAddingAccessory: false });
        showNotification('success', __('Product quantity changed!'));
    }

    async handleRemoveAccessory(item) {
        const { removeProduct, showNotification } = this.props;

        await removeProduct(item.item_id);
        this.setState({ isAddingAccessory: false });
        showNotification('success', __('Product removed from cart!'));
    }

    isSampleInCart() {
        const {
            sample: {
                sku
            } = {},
            cartTotals: {
                items = []
            } = {}
        } = this.props;
        const samplesInCart = items.map((product) => product.sku);

        return samplesInCart?.includes(sku);
    }

    isConfigurableProductOutOfStock() {
        const { product: { variants }, isPreview } = this.props;

        if (isPreview) {
            return true;
        }

        const variantsInStock = variants?.filter((productVariant) => productVariant.stock_status === IN_STOCK);

        return variantsInStock?.length === 0;
    }

    removeSample() {
        this.setState({ isRemovingProducts: true },
            () => this.removeProductAndUpdateCrossSell());
    }

    async removeProductAndUpdateCrossSell() {
        const {
            removeProduct,
            updateCrossSellProducts,
            cartTotals: {
                items
            } = {},
            sample: {
                sku
            } = {}
        } = this.props;

        if (items.length === 0) {
            this.setState({ isRemovingProducts: false });
            return null;
        }

        const item_id = items.filter((product) => product.sku === sku)
            ?.map((sample) => sample.item_id)?.[0];

        const result = await removeProduct(item_id);

        if (result) {
            await updateCrossSellProducts(result.items);
        }

        this.setState({ isRemovingProducts: false });

        return result;
    }

    changeToProductImage(e) {
        const {
            isSearchSpringSearch,
            simpleProductSwatches,
            configurableProductsSwatches: {
                products
            } = {},
            product: {
                dynamicAttributes: {
                    discontinued_product = ''
                }
            }
        } = this.props;

        const isDiscontinued = DISCONTINUED_PRODUCT_LABELS.includes(discontinued_product);

        if (isDiscontinued) {
            return;
        }

        let swatchImageUrl = '';

        // For configurable product swatches
        if (products?.length) {
            swatchImageUrl = products[0]?.swatchImageUrl;

        // For simple product swatches
        } else {
            swatchImageUrl = simpleProductSwatches?.[0]?.swatchImageUrl;
        }

        if (isSearchSpringSearch) {
            if (this._isThumbnailAvailable(swatchImageUrl)) {
                // eslint-disable-next-line no-param-reassign
                e.target.src = swatchImageUrl;
            }
        }
    }

    changeToSwatchImage(e) {
        const {
            isSearchSpringSearch,
            product: {
                small_image: {
                    url: thumbnailImageUrl
                },
                dynamicAttributes: {
                    discontinued_product = ''
                }
            } = {}
        } = this.props;

        const isDiscontinued = DISCONTINUED_PRODUCT_LABELS.includes(discontinued_product);

        if (isDiscontinued) {
            return;
        }

        if (isSearchSpringSearch) {
            if (this._isThumbnailAvailable(thumbnailImageUrl)) {
                // eslint-disable-next-line no-param-reassign
                e.target.src = thumbnailImageUrl;
            }
        }
    }

    onError(e) {
        const { placeholderImageUrl } = this.props;
        const errorImage = e.target;

        errorImage.onError = null;
        // eslint-disable-next-line max-len
        errorImage.src = placeholderImageUrl;
    }

    _isThumbnailAvailable(path) {
        return path && path !== 'no_selection' && !path.includes('placeholder');
    }

    _getThumbnail() {
        const {
            placeholderImageUrl,
            simpleProductSwatches,
            configurableProductsSwatches: {
                products
            } = {}
        } = this.props;

        // For configurable product swatches
        const { baseImageUrl: configurableBaseImageUrl = '' } = products?.[0] || '';

        if (this._isThumbnailAvailable(configurableBaseImageUrl)) {
            return configurableBaseImageUrl;
        }

        // Get thumbnail
        const { product: { small_image: { url: parentUrl } = {} } } = this.props;
        if (this._isThumbnailAvailable(parentUrl)) {
            return parentUrl;
        }

        // If thumbnail is missing get simple product swatches
        const { swatchImageUrl = '' } = simpleProductSwatches?.[0] || '';
        if (this._isThumbnailAvailable(swatchImageUrl)) {
            return swatchImageUrl;
        }

        // If parent is missing we try to get swatch image
        const { product: { swatch: { url: swatchUrl } = {} } } = this.props;
        if (this._isThumbnailAvailable(swatchUrl)) {
            return swatchUrl;
        }

        return placeholderImageUrl;
    }

    getUpsellProductSkus(type) {
        const { linkedProducts } = this.props;

        return linkedProducts[type]?.items?.map((product) => product.sku);
    }

    showOnlyIfLoaded(expression, content, placeholder = content) {
        if (!expression) {
            return placeholder;
        }

        return content;
    }

    setQuantity(value) {
        this.setState({ quantity: +value });
    }

    showMore(event) {
        const isMore = event.target.className.includes('ProductCard-Description_type_more');

        if (isMore) {
            document.getElementsByClassName('ProductCard-Description_type_more')[0].style.display = 'none';
            document.getElementsByClassName('ProductCard-Description_type_less')[0].style.display = 'inline';
            document.getElementsByClassName('ProductCard-Description_type_expanded_content')[0]
                .style.display = 'inline';
        } else {
            document.getElementsByClassName('ProductCard-Description_type_less')[0].style.display = 'none';
            document.getElementsByClassName('ProductCard-Description_type_more')[0].style.display = 'inline';
            document.getElementsByClassName('ProductCard-Description_type_expanded_content')[0].style.display = 'none';
        }
    }

    getAttribute(code) {
        const { selectedFilters = {} } = this.props;

        if (!Object.keys(selectedFilters).length) {
            const { product: { attributes = {} } } = this.props;
            return attributes[code];
        }

        const currentVariantIndex = this._getCurrentVariantIndex();
        const { product, product: { variants = [] } } = this.props;
        const { attributes: parentAttributes = {} } = product;
        const { attributes = parentAttributes } = variants[currentVariantIndex] || product;
        const { attribute_options = {} } = parentAttributes[code] || {};

        return {
            ...attributes[code],
            attribute_options
        };
    }

    render() {
        return (
            <Subscribe to={ [SharedTransitionContainer] }>
                { ({ registerSharedElement }) => (
                    <ProductCard
                      // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                      { ...{ ...this.props, registerSharedElement } }
                      { ...this.containerFunctions }
                      // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                      { ...this.state }
                      { ...this.containerProps() }
                    />
                ) }
            </Subscribe>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardContainer);
